/* eslint-disable @typescript-eslint/camelcase */
import {
  Actor,
  ClientDetail,
  ClientEntities,
  ClientMappingList,
  ClientParentData,
  ClientPartnerData,
  RequestApiClientMappingList
} from "@/domain/entities/Client";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { ClientPresenter } from "../presenters/ClientPresenter";
import { container } from "tsyringe";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RetryUploadClientRate } from "@/data/payload/api/ClientManagementApiRequest";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface ClientState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  clientData: ClientEntities;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
  actors: Actor[];
  clientDetailData: ClientDetail
}
@Module({ namespaced: true, dynamic: true, store, name: "client" })
class ClientStore extends VuexModule implements ClientState {
  public isLoading = false;
  public isLoadingDetail = false;
  public clientData = new ClientEntities(new Pagination(1, 10), []);
  public clientDetail = "";
  public clientPartnerData: Array<ClientPartnerData> = [];
  public clientMappingData: Array<ClientMappingList> = [];
  public clientParentData: Array<ClientParentData> = [];
  public search = "";
  public filterStatus = "";
  public isError = false;
  public isErrorEdit = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = true;
  public openSuccess = false;
  public isLoadingActor = false;
  public isErrorLoadActor = false;
  public actors: Actor[] = [];
  public clientDetailData: ClientDetail = new ClientDetail();

  @Action
  public _onGetList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
    clientParentId?: number;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ClientPresenter);
    presenter
      .getListClient(params.page, params.limit, params.search, params.status, params.clientParentId)
      .then((res: ClientEntities) => {
        this.setClientData({
          pagination: {
            page: res.pagination.page,
            limit: params.limit
          },
          data: res.data
        });
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getDetail(id: number) {
    this.setClientDetail({
      id: id,
      clientName: "PT. Tigakarsa Tbk",
      clientCode: "VEN009001",
      clientEmail: "client@clientmail.com",
      clientAddress:
        "Jl. Puri Indah Raya No.8, RT.1/RW.8, Kembangan Sel., Kec. Kembangan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11610",
      clientPriceRatesStartDate: "2020-05-05",
      clientPhone: "+6289989902010",
      clientNpwp: "99.999.999.9-999.999",
      clientVersionBasicPrice: "tigaraksa_custom_price",
      clientDiscount: "20%",
      clientVersionBasicPriceAttachment:
        "fileUploadCityRate _New_LP_12022020_1202221.xlxs",
      clientCustomPriceName: "hargaCustom_Tigaraksa_LP_jul20-des20",
      clientCustomPriceAttachment: "hargaCustom_Tigaraksa_LP_jul20-des20.xlsx",
      clientPriceRatesEndDate: "2020-06-06",
      clientRequestStatus: "rejected",
      clientPriceRatesStatus: "active",
      clientUpdatedAt: "2020-05-05, 10:00 WIB",
      clientUpdatedBy: "Bian",
      clientCreatedAt: "2020-05-05, 10:00 WIB",
      clientCreatedBy: "Bian"
    });
  }

  @Action
  public _onGetListPartner(params: {
    page: number;
    limit: number;
    search: string;
    partnerType: string;
    type?: string;
    isBranch?: boolean;
    isParent?: boolean;
    orderBy?: string;
    sortBy?: string;
  }) {
    this.setLoading(true);
    this.setClientPartnerData([]);
    const presenter = container.resolve(ClientPresenter);
    presenter
      .getListClientPartner(
        params.page,
        params.limit,
        params.search,
        params.partnerType,
        params.type,
        params.isBranch,
        params.isParent,
        params.orderBy,
        params.sortBy
      )
      .then((res: ClientPartnerData[]) => {
        this.setClientPartnerData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onGetListParent() {
    this.setLoading(true);
    this.setClientPartnerData([]);
    const presenter = container.resolve(ClientPresenter);
    presenter
      .getClientParentList()
      .then((res: ClientParentData[]) => {
        this.setClientParentData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onGetCLientMappingList(params: RequestApiClientMappingList) {
    this.setLoading(true);
    this.setClientMappingData([]);
    const presenter = container.resolve(ClientPresenter);
    presenter
      .getClientMappingList(params)
      .then((res: ClientMappingList[]) => {
        this.setClientMappingData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public fetchClientList() {
    this.setFirstRequest(false);
    this._onGetList({
      search: this.search,
      status: this.filterStatus,
      page: this.clientData.pagination.page,
      limit: this.clientData.pagination.limit
    });
  }

  @Action
  public initClient() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setFilterStatus("");
    this.setSearch("");
    this._onGetList({
      search: "",
      status: "",
      page: this.clientData.pagination.page,
      limit: this.clientData.pagination.limit
    });
  }

  @Action
  public setFirstPage() {
    this.clientData.pagination.page = 1;
  }

  @Action
  public selectStatusAction(params: { name: string; value: string }) {
    this.setFilterStatus(params.value);
    this.setFirstPage();
    this.fetchClientList();
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchClientList();
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Action
  public getListOfActor(search: string) {
    this.setLoading(true);
    const presenter = container.resolve(ClientPresenter);
    presenter
      .getListOfActor(search)
      .then((res: Actor[]) => {
        this.setListActor(res);
        this.setIsErrorLoadingActor(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setIsErrorLoadingActor(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setIsErrorLoadingActor(false);
      });
  }

  @Action
  public fetchClientDetail(clientId: number): Promise<ClientDetail> {
    this.setLoading(true);
    const presenter = container.resolve(ClientPresenter);

    return new Promise((resolve, reject) => {
      presenter
        .getClientDetail(clientId)
        .then(res => {
          this.setClientDetailData(res);
          resolve(res);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          this.setLoading(false);
        });
    });
  }

  @Mutation
  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setClientData(data: ClientEntities) {
    this.clientData = data;
  }

  @Mutation
  public setClientPartnerData(data: ClientPartnerData[]) {
    this.clientPartnerData = data;
  }

  @Mutation
  public setClientParentData(data: ClientParentData[]) {
    this.clientParentData = data;
  }

  @Mutation
  public setClientMappingData(data: ClientMappingList[]) {
    this.clientMappingData = data;
  }

  @Mutation
  private setClientDetail(data: any) {
    this.clientDetail = data;
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  private setFilterStatus(value: string) {
    this.filterStatus = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setIsLoadingActor(value: boolean) {
    this.isLoadingActor = value;
  }

  @Mutation
  public setIsErrorLoadingActor(value: boolean) {
    this.isErrorLoadActor = value;
  }

  @Mutation
  public setListActor(value: Actor[]) {
    this.actors = value;
  }

  @Action
  public async retryUploadClientRate(params: {
    clientId: number;
    rateVersionId: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientPresenter);
    return presenter
      .retryUploadClientRate(
        new RetryUploadClientRate(params.clientId, params.rateVersionId)
      )
      .then(() => {
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(parsingErrorResponse(err));
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setClientDetailData(data: ClientDetail): void {
    this.clientDetailData = data;
  }
}

export const ClientController = getModule(ClientStore);
